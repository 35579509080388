import React, { lazy, Suspense, useEffect } from 'react'

import { AnimatePresence } from 'framer-motion'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import ErrorBoundary from './components/common/ErrorBoundary'
import TopProgressBar from 'components/common/TopProgressBar'

import * as CONSTANT from 'common/constant'

const MinimalLayout = lazy(() => import('./layouts/Minimal'))
const AdminMainLayout = lazy(() => import('layouts/AdminMain'))

const AdminLoginView = lazy(() => import('./views/Admin/Login'))
const AdminSettingsView = lazy(() => import('./views/Admin/Settings'))
const AdminLotteriesView = lazy(() => import('./views/Admin/Lotteries'))
const AdminPrizesView = lazy(() => import('./views/Admin/Prizes'))
const ClientRegisterView = lazy(() => import('./views/Client/Register'))
const ClientPrizesView = lazy(() => import('./views/Client/Prizes'))
const ClientLotteryView = lazy(()=> import('./views/Client/Lottery'))
const ClientPriceSimulationView = lazy(()=> import('./views/Client/PriceSimulation'))
const ClientPrizeTypeSelect = lazy(()=> import('./views/Client/PrizeTypeSelect'))

const LineAccessView = lazy(() => import('./views/LineAccess'))
const LineFriendView = lazy(() => import('./views/LineFriend'))
const PermissionErrorView = lazy(() => import('./views/PermissionError'))
const NotFoundView = lazy(() => import('./views/NotFound'))

const AdminUsersView = lazy(() => import('./views/Admin/Users'))
const AdminPrizeOrdersView = lazy(() => import('./views/Admin/PrizeOrders'))
const AdminAudiencesView = lazy(() => import('./views/Admin/Audiences'))
const AdminQuestionAndAnswerEditView = lazy(() => import('./views/Admin/QeustionAndAnserEdit'))

const AdminDebugView = lazy(() => import('./views/Admin/Debug'))
const LiffProvider = lazy(() => import('./components/client/LiffProvider'))
const Redirector = lazy(()=> import('views/Client/Redirector'))

const ClientRiderect: React.FC = () => {
  useEffect(() => {
    const url = new URL(window.location.href)
    if (url.searchParams.has('liff.state')) {
      console.log('liff state redirect')
      const state = url.searchParams.get('liff.state')!.slice(1)
      const [path, search_param] = state.split('?') as [string, string | undefined]
      url.pathname = url.pathname.endsWith('/') ? `${url.pathname}${path}` : `${url.pathname}/${path}`
      if (search_param) url.search += '&' + search_param
      // search_paramを食わせないとログインにならないのでこのような処理になっている
      location.replace(String(url))
    }
  }, [])
  return <></>
}

const Router = () => {
  const location = useLocation()

  return (
    <ErrorBoundary>
      <Suspense fallback={<TopProgressBar />}>
        <AnimatePresence mode='wait'>
          <Routes key={location.pathname} location={location}>
            
            <Route path='/' element={<Navigate replace to={CONSTANT.ADMIN_LOGIN_ROUTE} />} />

            <Route
              path={CONSTANT.ADMIN_LOGIN_ROUTE}
              element={
                <MinimalLayout>
                  <AdminLoginView />
                </MinimalLayout>
              }
            />

            <Route
              path={CONSTANT.ADMIN_USERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminUsersView />
                </AdminMainLayout>
              }
            />

            <Route
              path={CONSTANT.ADMIN_AUDIENCES_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminAudiencesView />
                </AdminMainLayout>
              }
            />

            <Route
              path={CONSTANT.ADMIN_LOTTERIES_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminLotteriesView />
                </AdminMainLayout>
              }
            />
            <Route
              path={CONSTANT.ADMIN_PRIZES_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminPrizesView />
                </AdminMainLayout>
              }
            />
            <Route
              path={CONSTANT.ADMIN_PRIZEORDERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminPrizeOrdersView />
                </AdminMainLayout>
              }
            />

            <Route
              path={CONSTANT.ADMIN_SETTINGS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminSettingsView />
                </AdminMainLayout>
              }
            />

            <Route
              path={CONSTANT.ADMIN_QUESTIONS_AND_ANSWERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminQuestionAndAnswerEditView />
                </AdminMainLayout>
              }
            />

            {import.meta.env.VITE_APP_ENV !== 'PRODUCTION' && <Route
              path='debug'
              element={
                <AdminMainLayout>
                  <AdminDebugView />
                </AdminMainLayout>
              }
            />}
            
            <Route path={CONSTANT.CLIENT_ROOT}
              element={<ClientRiderect/>}
            />

            <Route
              element={<LiffProvider/>}
            >
              <Route
                path={CONSTANT.CLIENT_REGISTER_ROUTE}
                element={
                  <MinimalLayout>
                    <ClientRegisterView />
                  </MinimalLayout>
                }
              />
              
              <Route
                path={CONSTANT.CLIENT_PRIZES_ROOT}
                element={
                  <MinimalLayout>
                    <ClientPrizesView />
                  </MinimalLayout>
                }
              />

              <Route
                path={CONSTANT.CLIENT_LOTTERY_ROOT}
                element={
                  <MinimalLayout>
                    <ClientLotteryView />
                  </MinimalLayout>
                }
              />
            </Route>

            <Route
              path='/liff/redirector'
              element={<Redirector/>}
            />

            <Route
              path={CONSTANT.CLIENT_PRICESIMULATION_ROUTE}
              element={
                <MinimalLayout>
                  <ClientPriceSimulationView />
                </MinimalLayout>
              }
            />
            
            <Route
              path={CONSTANT.CLIENT_PRIZETYPESELECT_ROUTE}
              element={
                <MinimalLayout>
                  <ClientPrizeTypeSelect />
                </MinimalLayout>
              }
            />

            <Route
              path={CONSTANT.LINE_ACCESS_ROUTE}
              element={
                <MinimalLayout>
                  <LineAccessView />
                </MinimalLayout>
              }
            />

            <Route
              path={CONSTANT.LINE_FRIEND_ROUTE}
              element={
                <MinimalLayout>
                  <LineFriendView />
                </MinimalLayout>
              }
            />
            <Route
              path={CONSTANT.PERMISSION_ERROR_ROUTE}
              element={
                <MinimalLayout>
                  <PermissionErrorView />
                </MinimalLayout>
              }
            />
            <Route
              path={CONSTANT.NOT_FOUND_ROUTE}
              element={
                <MinimalLayout>
                  <NotFoundView />
                </MinimalLayout>
              }
            />
            <Route path='*' element={<Navigate replace to={CONSTANT.NOT_FOUND_ROUTE} />} />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </ErrorBoundary>
  )
}

export default Router
